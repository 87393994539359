export enum DimensionsEnum {
  AgeGroup5 = 'ageGroup5',
  Gender = 'gender',
  ZipCode = 'zipCode',
  IrisCode = 'irisCode',
  Revenue = 'revenue',
  Department = 'department',
  District = 'district',
  FamilySquare = 'familySquare',
}
