import { Injectable } from '@angular/core';
import { APIResponse, APIResponseValidation } from '@dmc-ng/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, of, switchMap } from 'rxjs';

import { CountingsService } from '../countings.service';
import { CountDetailsModel } from '../models/count.model';
import {
  CountingPaginatedModel,
  CountingVersionModel,
} from '../models/counting-version.model';
import { CountingModel } from '../models/counting.model';
import { CreateCountingVersionModel } from '../models/create-counting-version.model';
import { DatasetModel } from '../models/dataset.model';
import { FullCountingModel } from '../models/full-counting.model';
import { TokenShareModel } from '../models/token.model';
import { countingsActions } from './countings.actions';

@Injectable()
export class CountingsEffects {
  paginatedCountings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.getPaginatedCountings),
      switchMap(({ accountId, organizationId, offset, limit }) =>
        this.countingsService
          .getAllCountings(organizationId, accountId, offset, limit)
          .pipe(
            map((countingsResult: APIResponse<CountingPaginatedModel>) => {
              const { total, items } =
                countingsResult.data as CountingPaginatedModel;
              return countingsActions.paginatedCountingsRetrievedSuccessfully({
                countings: items,
                total,
                totalInCurrentOffset: items.length,
              });
            }),
            catchError((error) =>
              of(
                countingsActions.paginatedCountingsFailedToBeRetrieved({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  createCounting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.createCounting),
      switchMap(({ organizationId, accountId, counting, countingVersion }) =>
        this.countingsService
          .postCounting(organizationId, accountId, counting)
          .pipe(
            map((result: APIResponseValidation<CountingModel>) =>
              countingsActions.countingCreatedSuccessfully({
                counting: result.data as CountingModel,
                countingVersion,
              }),
            ),
            catchError((error) =>
              of(countingsActions.countingFailedToBeCreated({ error })),
            ),
          ),
      ),
    ),
  );

  updateCounting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.updateCounting),
      switchMap(({ organizationId, accountId, countingId, counting }) =>
        this.countingsService
          .putCounting(organizationId, accountId, countingId, counting)
          .pipe(
            map((result: APIResponseValidation<CountingModel>) =>
              countingsActions.countingUpdatedSuccessfully({
                counting: result.data as CountingModel,
              }),
            ),
            catchError((error) =>
              of(countingsActions.countingFailedToBeUpdated({ error })),
            ),
          ),
      ),
    ),
  );

  countingsWithAllVersions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.getCountingWithAllVersions),
      mergeMap(({ accountId, organizationId, countingId }) =>
        this.countingsService
          .getAllVersionsCounting(organizationId, accountId, countingId)
          .pipe(
            map((countingResult: APIResponse<FullCountingModel>) =>
              countingsActions.countingWithAllVersionsRetrievedSuccessfully({
                counting: countingResult.data as FullCountingModel,
              }),
            ),
            catchError((error) =>
              of(
                countingsActions.countingWithAllVersionsFailedToBeRetrieved({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  createCountingVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.createCountingVersion),
      switchMap(
        ({
          organizationId,
          accountId,
          countingId,
          countingVersion,
          fromCreate,
        }) =>
          this.countingsService
            .postNewVersionCounting(
              organizationId,
              accountId,
              countingId,
              countingVersion,
            )
            .pipe(
              map((result: APIResponseValidation<CountingVersionModel>) =>
                countingsActions.countingVersionCreatedSuccessfully({
                  countingVersion: result.data as CountingVersionModel,
                  fromCreate,
                }),
              ),
              catchError((error) =>
                of(
                  countingsActions.countingVersionFailedToBeCreated({ error }),
                ),
              ),
            ),
      ),
    ),
  );

  getTargetReach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.getTargetReach),
      switchMap(({ organizationId, accountId, filters, dimensions }) =>
        this.countingsService
          .postFilters(organizationId, accountId, filters, dimensions)
          .pipe(
            map((result: APIResponse<CountDetailsModel>) =>
              countingsActions.targetReachRetrievedSuccessfully({
                count: (result.data as CountDetailsModel).total,
                counts: (result.data as CountDetailsModel).counts,
              }),
            ),
            catchError((error) =>
              of(countingsActions.targetReachFailedToBeRetrieved({ error })),
            ),
          ),
      ),
    ),
  );

  createCountingAndCountingVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.countingCreatedSuccessfully),
      filter(({ countingVersion }) => !!countingVersion),
      map(({ counting, countingVersion }) =>
        countingsActions.createCountingVersion({
          accountId: counting.accountId,
          countingId: counting.id,
          organizationId: counting.organizationId,
          countingVersion: countingVersion as CreateCountingVersionModel,
          fromCreate: true,
        }),
      ),
    ),
  );

  uploadCountingDatasetCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.uploadBlacklistDatabaseWithCampaignIds),
      switchMap(({ organizationId, accountId, campaignIds, file }) => {
        const formData = new FormData();

        if (file) {
          formData.append('file', file);
        }

        if (campaignIds && campaignIds.length > 0) {
          formData.append('campaignIds', campaignIds.join(','));
        }

        return this.countingsService
          .postBlacklistCSVWithCampaignIds(organizationId, accountId, formData)
          .pipe(
            map((result: APIResponse<DatasetModel>) =>
              countingsActions.blacklistDatabaseWithCampaignIdsSentSuccessfully(
                {
                  datasetId: result?.data?.datasetId as string,
                  datasetName: result?.data?.datasetName as string,
                },
              ),
            ),
            catchError((error) =>
              of(
                countingsActions.blacklistDatabaseWithCampaignIdsFailedToBeValidated(
                  { error },
                ),
              ),
            ),
          );
      }),
    ),
  );

  deleteCounting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.deleteCounting),
      switchMap(({ organizationId, accountId, countingId, offset }) =>
        this.countingsService
          .deleteCounting(organizationId, accountId, countingId)
          .pipe(
            map(() =>
              countingsActions.countingDeleteSuccessfully({
                countingId,
                offset,
              }),
            ),
            catchError((error) =>
              of(countingsActions.countingFailedToBeDeleted({ error })),
            ),
          ),
      ),
    ),
  );

  getCountingsWithSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.getCountingsWithSearch),
      switchMap(({ accountId, organizationId, query, limit, withVersions }) =>
        this.countingsService
          .searchCountings(
            organizationId,
            accountId,
            query,
            limit,
            withVersions,
          )
          .pipe(
            map((countingsResult: APIResponse<FullCountingModel[]>) =>
              countingsActions.countingsSearchSuccessfully({
                countings: countingsResult.data as FullCountingModel[],
              }),
            ),
            catchError((error) =>
              of(
                countingsActions.countingFailedToBeSearched({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteCountingVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.deleteCountingVersion),
      switchMap(
        ({ organizationId, accountId, countingId, countingVersionId }) =>
          this.countingsService
            .deleteCountingVersion(
              organizationId,
              accountId,
              countingId,
              countingVersionId,
            )
            .pipe(
              map(() =>
                countingsActions.countingVersionDeleteSuccessfully({
                  countingVersionId,
                }),
              ),
              catchError((error) =>
                of(
                  countingsActions.countingVersionFailedToBeDeleted({ error }),
                ),
              ),
            ),
      ),
    ),
  );

  getTokenShared$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countingsActions.getTokenShare),
      switchMap(
        ({
          organizationId,
          accountId,
          countingId,
          countingVersionId,
          tokenParams,
        }) =>
          this.countingsService
            .postTokenShare(
              organizationId,
              accountId,
              countingId,
              countingVersionId,
              tokenParams,
            )
            .pipe(
              map((result: APIResponse<TokenShareModel>) =>
                countingsActions.tokenShareRetrievedSuccessfully({
                  token: (result.data as TokenShareModel).tokenValue,
                }),
              ),
              catchError((error) =>
                of(countingsActions.tokenShareFailedToBeRetrieved({ error })),
              ),
            ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private countingsService: CountingsService,
  ) {}
}
