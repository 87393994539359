import { LoadingStateEnum } from '@dmc-ng/data-access';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { isArray } from 'lodash';

import { countingsActions } from './countings.actions';
import { CountModel } from '../models/count.model';
import { CountingModel } from '../models/counting.model';
import { FullCountingModel } from '../models/full-counting.model';

export const COUNTINGS_FEATURE_KEY = 'countings';

export const countingListAdapter: EntityAdapter<CountingModel> =
  createEntityAdapter<CountingModel>();
export const countingsDetailsAdapter: EntityAdapter<FullCountingModel> =
  createEntityAdapter<FullCountingModel>();
export const countingsSearchAdapter: EntityAdapter<FullCountingModel> =
  createEntityAdapter<FullCountingModel>();

export interface CountingsState {
  countsDetails?: CountModel[];
  countingList: CountingListState;
  countingsDetails: CountingsDetailsState;
  countingsSearch: CountingsSearchState;
  error?: unknown;
  loading: LoadingStateEnum;
  loadingTargetReach: LoadingStateEnum;
  loadingTokenShare: LoadingStateEnum;
  countingDatasets?: CountingDatasetsState;
}

export interface CountingsDetailsState extends EntityState<FullCountingModel> {
  error?: unknown;
  loading: LoadingStateEnum;
}

export interface CountingsSearchState extends EntityState<FullCountingModel> {
  error?: unknown;
  loading: LoadingStateEnum;
}

export interface CountingListState extends EntityState<CountingModel> {
  error?: unknown;
  total?: number;
  totalInCurrentOffset?: number;
  loading: LoadingStateEnum;
}

export interface CountingDatasetsState {
  loading: LoadingStateEnum;
  datasetId?: string;
  datasetName?: string;
  error?: unknown;
}

export const initialCountingsState: CountingsState = {
  loading: LoadingStateEnum.Ready,
  loadingTargetReach: LoadingStateEnum.Ready,
  loadingTokenShare: LoadingStateEnum.Ready,
  countingList: countingListAdapter.getInitialState({
    loading: LoadingStateEnum.Ready,
  }),
  countingsDetails: countingsDetailsAdapter.getInitialState({
    loading: LoadingStateEnum.Ready,
  }),
  countingsSearch: countingsSearchAdapter.getInitialState({
    loading: LoadingStateEnum.Ready,
  }),
};

const reducer = createReducer(
  initialCountingsState,
  on(
    countingsActions.createCounting,
    countingsActions.createCountingVersion,
    countingsActions.updateCounting,
    countingsActions.deleteCounting,
    countingsActions.deleteCountingVersion,
    (state) => ({ ...state, loading: LoadingStateEnum.Loading }),
  ),
  on(
    countingsActions.countingFailedToBeCreated,
    countingsActions.countingVersionFailedToBeCreated,
    countingsActions.countingFailedToBeUpdated,
    countingsActions.countingFailedToBeDeleted,
    countingsActions.countingVersionFailedToBeDeleted,
    (state, { error }) => ({ ...state, error, loading: LoadingStateEnum.Done }),
  ),
  on(
    countingsActions.countingCreatedSuccessfully,
    countingsActions.countingVersionCreatedSuccessfully,
    countingsActions.countingUpdatedSuccessfully,
    countingsActions.countingDeleteSuccessfully,
    countingsActions.countingVersionDeleteSuccessfully,
    (state) => ({ ...state, loading: LoadingStateEnum.Done }),
  ),

  on(countingsActions.getTargetReach, (state) => ({
    ...state,
    loadingTargetReach: LoadingStateEnum.Loading,
  })),
  on(
    countingsActions.targetReachRetrievedSuccessfully,
    (state, { counts }) => ({
      ...state,
      countsDetails: counts,
      loadingTargetReach: LoadingStateEnum.Done,
    }),
  ),
  on(countingsActions.targetReachFailedToBeRetrieved, (state, { error }) => ({
    ...state,
    error,
    loadingTargetReach: LoadingStateEnum.Done,
  })),
  on(countingsActions.getTokenShare, (state) => ({
    ...state,
    loadingTokenShare: LoadingStateEnum.Loading,
  })),
  on(countingsActions.tokenShareRetrievedSuccessfully, (state) => ({
    ...state,
    loadingTokenShare: LoadingStateEnum.Done,
  })),
  on(countingsActions.tokenShareFailedToBeRetrieved, (state, { error }) => ({
    ...state,
    error,
    loadingTokenShare: LoadingStateEnum.Done,
  })),
  on(countingsActions.getPaginatedCountings, (state) => ({
    ...state,
    countingList: {
      ...state.countingList,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    countingsActions.paginatedCountingsRetrievedSuccessfully,
    (state, { countings, total, totalInCurrentOffset }) => ({
      ...state,
      countingList: {
        ...countingListAdapter.setAll(countings, state.countingsDetails),
        loading: LoadingStateEnum.Done,
        total,
        totalInCurrentOffset,
      },
    }),
  ),
  on(
    countingsActions.paginatedCountingsFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      countingList: {
        ...state.countingList,
        loading: LoadingStateEnum.Done,
        error,
      },
    }),
  ),
  on(countingsActions.getCountingWithAllVersions, (state) => ({
    ...state,
    countingsDetails: {
      ...state.countingsDetails,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    countingsActions.countingWithAllVersionsRetrievedSuccessfully,
    (state, { counting }) => ({
      ...state,
      countingsDetails: {
        ...countingsDetailsAdapter.upsertOne(counting, state.countingsDetails),
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(
    countingsActions.countingWithAllVersionsFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      countingsDetails: {
        ...state.countingsDetails,
        loading: LoadingStateEnum.Done,
        error,
      },
    }),
  ),

  on(countingsActions.uploadBlacklistDatabaseWithCampaignIds, (state) => ({
    ...state,
    countingDatasets: {
      ...state.countingDatasets,
      datasetName: undefined,
      datasetId: undefined,
      error: undefined,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    countingsActions.blacklistDatabaseWithCampaignIdsSentSuccessfully,
    (state, { datasetId, datasetName }) => ({
      ...state,
      countingDatasets: {
        ...state.countingDatasets,
        error: undefined,
        datasetId,
        datasetName,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(
    countingsActions.blacklistDatabaseWithCampaignIdsFailedToBeValidated,
    (state, { error }) => ({
      ...state,
      countingDatasets: {
        ...state.countingDatasets,
        error,
        datasetId: undefined,
        datasetName: undefined,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(countingsActions.resetDatasetStore, (state) => ({
    ...state,
    countingDatasets: {
      ...state.countingDatasets,
      error: undefined,
      datasetId: undefined,
      datasetName: undefined,
      loading: LoadingStateEnum.Ready,
    },
  })),
  on(countingsActions.getCountingsWithSearch, (state) => ({
    ...state,
    countingsSearch: {
      ...state.countingsSearch,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(countingsActions.countingsSearchSuccessfully, (state, { countings }) => ({
    ...state,
    countingsSearch: {
      ...countingsSearchAdapter.setAll(
        isArray(countings) ? countings : [],
        state.countingsSearch,
      ),
      loading: LoadingStateEnum.Done,
    },
  })),
  on(countingsActions.countingFailedToBeSearched, (state, { error }) => ({
    ...state,
    countingsSearch: {
      ...state.countingsSearch,
      loading: LoadingStateEnum.Done,
      error,
    },
  })),
  on(countingsActions.resetCountingWithSearch, (state) => ({
    ...state,
    countingsSearch: {
      ...countingsSearchAdapter.removeAll(state.countingsSearch),
      loading: LoadingStateEnum.Done,
      error: undefined,
    },
  })),
  on(countingsActions.resetCountingDetails, (state) => ({
    ...state,
    countingsDetails: {
      ...countingsDetailsAdapter.removeAll(state.countingsDetails),
      loading: LoadingStateEnum.Ready,
      error: undefined,
    },
  })),
);

export function countingsReducer(
  state: CountingsState | undefined,
  action: Action,
): CountingsState {
  return reducer(state, action);
}
